exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-epikoinwnia-js": () => import("./../../../src/pages/epikoinwnia.js" /* webpackChunkName: "component---src-pages-epikoinwnia-js" */),
  "component---src-pages-form-test-el-js": () => import("./../../../src/pages/form-test-el.js" /* webpackChunkName: "component---src-pages-form-test-el-js" */),
  "component---src-pages-form-test-js": () => import("./../../../src/pages/form-test.js" /* webpackChunkName: "component---src-pages-form-test-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-slug-js": () => import("./../../../src/pages/{Page.slug}.js" /* webpackChunkName: "component---src-pages-page-slug-js" */),
  "component---src-pages-poioi-eimaste-js": () => import("./../../../src/pages/poioi-eimaste.js" /* webpackChunkName: "component---src-pages-poioi-eimaste-js" */),
  "component---src-templates-custom-product-category-js": () => import("./../../../src/templates/custom-product-category.js" /* webpackChunkName: "component---src-templates-custom-product-category-js" */),
  "component---src-templates-custom-product-category-mix-js": () => import("./../../../src/templates/custom-product-category-mix.js" /* webpackChunkName: "component---src-templates-custom-product-category-mix-js" */),
  "component---src-templates-custom-template-product-js": () => import("./../../../src/templates/custom-template-product.js" /* webpackChunkName: "component---src-templates-custom-template-product-js" */),
  "component---src-templates-post-category-js": () => import("./../../../src/templates/post-category.js" /* webpackChunkName: "component---src-templates-post-category-js" */),
  "component---src-templates-product-category-js": () => import("./../../../src/templates/product-category.js" /* webpackChunkName: "component---src-templates-product-category-js" */),
  "component---src-templates-product-en-js": () => import("./../../../src/templates/product-en.js" /* webpackChunkName: "component---src-templates-product-en-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-project-category-js": () => import("./../../../src/templates/project-category.js" /* webpackChunkName: "component---src-templates-project-category-js" */),
  "component---src-templates-project-en-js": () => import("./../../../src/templates/project-en.js" /* webpackChunkName: "component---src-templates-project-en-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

